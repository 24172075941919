import React, { Component, Suspense, useState, useEffect } from "react";


import HomeCanvas from "../components/HomeCanvas";

const CanvasTest = () => {
  return (
    <main>
      <HomeCanvas />
    </main>
    
  )
}

export default CanvasTest